import React, {Component} from 'react';
import Hls from 'hls.js';
import dashjs from 'dashjs';
import './VideoPlayer.css';
import eventBus from "./EventBus";
import {scheduleNonBlocking, unschedule} from "./Scheduler";

export default class VideoPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            video: {
                width: "100%",
                autoPlay: true,
                controls: true,
            },
            videoProcessingDelay: 0,
            fragmentLength: 3
        }
    }

    componentDidMount(props) {
        const video = this.player;
        video.muted = true;
        const _this = this;

        this.initPlayer('hls');

        this.playScheduler = scheduleNonBlocking(() => {
            let currentTime, programDateTime;
            if (_this.streamType === 'hls') {
                if (_this.currentFragment) {
                    currentTime = video.currentTime;
                    programDateTime = _this.currentFragment.programDateTime - _this.currentFragment.start * 1000;
                }
            } else if (_this.streamType === 'dash') {
                const info = _this.dash.getDashMetrics().getCurrentDVRInfo('video');
                if (info) {
                    currentTime = info.time;
                    programDateTime = info.manifestInfo.availableFrom.getTime();
                }
            }

            const time = Math.round(currentTime * 1000 + programDateTime - _this.state.videoProcessingDelay);
            if (time) {
                eventBus.dispatch("playStatus", {time: time});
                _this.setState({
                    "time": new Date(time).toISOString()
                });
            }
        }, 100);

        eventBus.on("terminate", (data) => {
            if (data.type === 'video') {
                _this.setState({"time": ""})
            }
        });

        eventBus.on("videoStatus", (data) => {
            _this.streamType !== data.streamType && _this.initPlayer(data.streamType);

            if (data.firstPartPublishedTime && data.startTime) {
                _this.setState({
                    videoProcessingDelay: Date.parse(data.firstPartPublishedTime) - Date.parse(data.startTime) - _this.state.fragmentLength * 1000
                });
            }
        })
    }

    componentWillUnmount() {
        eventBus.remove("playStatus");
        unschedule(this.playScheduler);
    }

    initPlayer(streamType) {
        if (streamType === 'hls') {
            this.streamType = streamType;
            this.destroyDashPlayer();
            this.initHlsPlayer();
        } else if (streamType === 'dash') {
            this.streamType = streamType;
            this.destroyHlsPlayer();
            this.initDashPlayer();
        }
    }

    initHlsPlayer() {
        const _this = this;
        const video = this.player;

        const hls = new Hls({
            manifestLoadingMaxRetry: Number.MAX_SAFE_INTEGER,
            manifestLoadingRetryDelay: 1000,
            manifestLoadingMaxRetryTimeout: 1000
        });
        hls.loadSource(window._env_.HLS_URL);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
            video.play();
        });
        hls.on(Hls.Events.FRAG_CHANGED, function (event, data) {
            _this.currentFragment = data.frag;
        });
        this.hls = hls;
    }

    destroyHlsPlayer() {
        if (this.hls) {
            this.hls.destroy();
        }
    }

    initDashPlayer() {
        const video = this.player;
        const dash = dashjs.MediaPlayer().create();

        dash.updateSettings({
            streaming: {
                retryAttempts: {
                    MPD: Number.MAX_SAFE_INTEGER
                },
                retryIntervals: {
                    MPD: 1000
                }
            }
        });

        dash.initialize(video, window._env_.DASH_URL, true);
        this.dash = dash;
    }

    destroyDashPlayer() {
        if (this.dash) {
            this.dash.reset();
        }
    }

    render() {
        return (
            <div className="video-player">
                <h2>Video</h2>
                <div>Current frame time: {this.state.time || 'N/A'} | Video processing
                    delay: {this.state.videoProcessingDelay} msec
                </div>
                <div>
                    <video ref={player => (this.player = player)} {...this.state.video}/>
                </div>
            </div>
        )
    }
}
