import React, {useEffect, useRef} from 'react'

const Canvas = props => {

    const canvasRef = useRef(null);
    const colors = ["#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080", "#0000FF"];

    const draw = (ctx, dots, horses, colors) => {
        dots.forEach(dot => {
            if (horses[dot.plate - 1].show) {
                drawDot(ctx, dot.x, dot.y, 5, colors[dot.plate - 1]);
            }
        });
    }

    const drawDot = (ctx, x, y, radius, color) => {
        ctx.fillStyle = color;
        ctx.beginPath()
        ctx.arc(x + 600, 425 - y, radius, 0, 2 * Math.PI, false);
        ctx.fill()
    }

    const drawLine = (ctx, x1, y1, x2, y2, color) => {
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(x1 + 600, 425 - y1);
        ctx.lineTo(x2 + 600, 425 - y2);
        ctx.stroke();
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        if (props.track && props.track.geometry) {
            const geometry = props.track.geometry;

            // borders
            geometry.outerBorder.forEach(dot => {
                drawDot(context, dot.x, dot.y, 2, '#636363');
            });
            geometry.innerBorders[0].forEach(dot => {
                drawDot(context, dot.x, dot.y, 2, '#636363');
            });

            // finish
            drawDot(context, geometry.finishLine.outerPoint.x, geometry.finishLine.outerPoint.y, 3, '#FF0000');
            drawDot(context, geometry.finishLine.innerPoint.x, geometry.finishLine.innerPoint.y, 3, '#FF0000');
            drawLine(context, geometry.finishLine.outerPoint.x, geometry.finishLine.outerPoint.y, geometry.finishLine.innerPoint.x, geometry.finishLine.innerPoint.y, '#FF0000');

            // start line
            for (let startLineKey in geometry.startLinesByDistance) {
                if (Object.prototype.hasOwnProperty.call(geometry.startLinesByDistance, startLineKey)) {
                    const startLine = geometry.startLinesByDistance[startLineKey];
                    drawDot(context, startLine.outerPoint.x, startLine.outerPoint.y, 3, '#0000FF');
                    drawDot(context, startLine.innerPoint.x, startLine.innerPoint.y, 3, '#0000FF');
                    drawLine(context, startLine.outerPoint.x, startLine.outerPoint.y, startLine.innerPoint.x, startLine.innerPoint.y, '#0000FF');
                }
            }

            // devices
            /*for (let deviceKey in props.track.devices) {
                let device = props.track.devices[deviceKey];
                let point = props.track.deviceMountingPoints[device.mac];
                drawDot(context, point.x, point.y, 3, device.type === 'Lidar' ? '#0000FF' : '#FF0000');
            }*/
        }

        if (props.objects) {
            //Our draw come here
            draw(context, props.objects, props.horses, props.colors);
        }
    }, [props, draw, drawDot]);

    return <canvas ref={canvasRef} {...props}/>
}

export default Canvas
