import React, {Component} from 'react';
import ControlPlain from "./ControlPlain";
import VideoPlayer from "./VideoPlayer";
import DataPlayer from "./DataPlayer";
import Login from "./Login";

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            token: ''
        }
    }

    setToken(token) {
        this.setState({token: token})
    }

    logout() {
        localStorage.clear();
        this.setState({
            token: ''
        })
    }

    render() {
        const {token} = this.state;

        if (!token) {
            return <Login setToken={this.setToken.bind(this)}/>
        }

        return (
            <div className="App">
                <div className="text-right"><a href="#" onClick={() => this.logout()}>Logout</a></div>
                <ControlPlain/>
                <div className="flex">
                    <VideoPlayer/>
                    <DataPlayer/>
                </div>
            </div>
        )
    }
}
