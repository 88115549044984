import React, {Component} from 'react';
import eventBus from "./EventBus";
import './ControlPlain.css'
import {isScheduled, schedule, unschedule} from "./Scheduler";

export default class ControlPlain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            video: {status: "REQUESTING DATA..."},
            data: {status: "REQUESTING DATA..."},
            filenames: ["210509-R7"],
            filename: "210509-R7",
            delay: 0,
            emulateRealTimeStream: true,
            hls: true,
            timers: {
                videoStreamCountDownTimer: null,
                videoStreamStarterTimer: null
            },
            videoStreamCountDown: 0,
            controlPlainPanelClass: 'height-0'
        }
        this.schedulers = {}
    }

    componentDidMount() {
        this.scheduleDataStatusPoller();
        this.scheduleVideoStatusPoller();

        eventBus.on("videoStatus", (data) => {
            if (data.streamType === 'dash') {
                this.setState({
                    hls: false
                });
            }
        });
    }

    componentWillUnmount() {
        eventBus.remove("serve");
        eventBus.remove("terminate");

        this.unscheduleDataStatusPoller();
        this.unscheduleVideoStatusPoller();
        unschedule(this.countDownScheduler);
    }

    scheduleDataStatusPoller() {
        if (isScheduled(this.dataStatusScheduler)) {
            return;
        }
        this.dataStatusScheduler = schedule(() => this.fetchStatus(window._env_.DATA_STREAM_EMULATOR_URL + '/status', 'data'), 1000);
    }

    unscheduleDataStatusPoller() {
        unschedule(this.dataStatusScheduler);
    }

    scheduleVideoStatusPoller() {
        if (isScheduled(this.videoStatusScheduler)) {
            return;
        }
        this.videoStatusScheduler = schedule(() => this.fetchStatus(window._env_.VIDEO_STREAM_EMULATOR_URL + '/status', 'video'), 1000);
    }

    unscheduleVideoStatusPoller() {
        unschedule(this.videoStatusScheduler);
    }

    async fetchStatus(url, key) {
        try {
            const response = await fetch(url);
            const status = await response.json();
            this.setState({
                [key]: status
            });
            eventBus.dispatch(key + "Status", status);
        } catch (error) {
            this.setState({
                [key]: {
                    status: "ERROR LOADING DATA",
                    error: error.message
                }
            });
        }
    }

    serve = () => {
        this.serveData();
        this.serveOrScheduleVideo();
    }

    serveData() {
        const dataFileUrl = window._env_.DATA_STREAM_EMULATOR_URL + '/serve/PP-' + this.state.filename + '.json?correctTime=' + this.state.emulateRealTimeStream;
        this.serveFile(dataFileUrl, 'data');
    }

    serveVideo() {
        const videoFileUrl = window._env_.VIDEO_STREAM_EMULATOR_URL + '/serve' + (this.state.hls ? '/hls/' : '/dash/') + this.state.filename + '.mp4';
        this.serveFile(videoFileUrl, 'video')
    }

    serveOrScheduleVideo() {
        unschedule(this.countDownScheduler);

        const delay = this.state.delay;

        if (delay <= 0) {
            this.serveVideo();
        } else {
            this.scheduleVideoCountDown();
        }
    }

    scheduleVideoCountDown() {
        unschedule(this.countDownScheduler);
        this.unscheduleVideoStatusPoller();

        let delay = this.state.delay;

        this.countDownScheduler = schedule(() => {
            if (delay > 0) {
                this.setState({
                    video: {
                        status: "Start streaming in " + delay-- + " seconds"
                    }
                });
            } else {
                this.unscheduleVideoCountDown();
                this.serveVideo();
            }
        }, 1000);
    }

    unscheduleVideoCountDown() {
        unschedule(this.countDownScheduler);
        this.scheduleVideoStatusPoller();
    }

    serveFile(url, type) {
        fetch(url).then(() => {
            eventBus.dispatch("serve", {type: type, mode: this.state.hls ? 'hls' : 'dash'});
        })
    }

    terminate = () => {
        this.unscheduleVideoCountDown();
        fetch(window._env_.DATA_STREAM_EMULATOR_URL + "/terminate")
        eventBus.dispatch("terminate", {type: 'data'});
        fetch(window._env_.VIDEO_STREAM_EMULATOR_URL + "/terminate")
        eventBus.dispatch("terminate", {type: 'video'});
    }

    handleFileNameChange(event) {
        this.setState({filename: event.target.value});
    }

    handleDelayChange(event) {
        this.setState({delay: event.target.value});
    }

    handleRealTimeChange(event) {
        this.setState({emulateRealTimeStream: event.target.checked});
    }

    handleHlsChange(event) {
        this.setState({hls: event.target.checked});
    }

    hide = () => {
        this.setState({
            controlPlainPanelClass: 'height-0'
        });
    }

    show = () => {
        this.setState({
            controlPlainPanelClass: 'height-100'
        });
    }

    render() {
        const {video, data} = this.state;
        return (
            <div>
                <div className={this.state.controlPlainPanelClass}>
                    <h2>Status</h2>
                    <div className="flex status-header">
                        <div></div>
                        <div><strong>Status</strong></div>
                        <div><strong>Filename</strong></div>
                        <div><strong>Errors</strong></div>
                    </div>
                    <div className="flex status-row">
                        <div><strong>Video stream</strong></div>
                        <code>{video.status}</code>
                        <code>{video.filename}</code>
                        <code>{video.error}</code>
                    </div>
                    <div className="flex status-row">
                        <div><strong>Data stream</strong></div>
                        <code>{data.status}</code>
                        <code>{data.filename}</code>
                        <code>{data.error}</code>
                    </div>

                    <div className="mt-20">
                        <div className="form-field">
                            <label htmlFor="fileName">File name</label>
                            <select className="input" onChange={(e) => this.handleFileNameChange(e)}>
                                {this.state.filenames.map((filename, i) =>
                                    <option key={"option" + i} value={filename}>{filename}</option>)}
                            </select>
                        </div>
                        <div className="form-field display-none">
                            <label htmlFor="delay">Delay</label>
                            <input id="delay" className="input w-50" type="text" value={this.state.delay}
                                   onChange={(e) => this.handleDelayChange(e)}
                                   size="1"/>
                        </div>
                        <div className="form-field display-none">
                            <label htmlFor="realTime">Real-time</label>
                            <div className=""/>
                            <input id="realTime" className="input w-50" type="checkbox"
                                   value={this.state.emulateRealTimeStream}
                                   checked={this.state.emulateRealTimeStream}
                                   onChange={(e) => this.handleRealTimeChange(e)}
                                   size="1"/>
                        </div>
                        <div className="form-field display-none">
                            <label htmlFor="realTime">Hls</label>
                            <div className=""/>
                            <input id="hls" className="input w-50" type="checkbox"
                                   value={this.state.hls}
                                   checked={this.state.hls}
                                   onChange={(e) => this.handleHlsChange(e)}
                                   size="1"/>
                        </div>
                        <button className="margin-10" onClick={this.serve}>Serve all</button>
                        <button className="margin-10 display-none" onClick={() => this.serveData()}>Serve data</button>
                        <button className="margin-10 display-none" onClick={() => this.serveOrScheduleVideo()}>Serve video</button>
                        <button className="margin-10" onClick={this.terminate}>Terminate</button>
                    </div>
                </div>
                <div className="center">
                    {this.state.controlPlainPanelClass === 'height-100' &&
                    <div className="showHideBar" onClick={this.hide}><i className="arrow up"></i></div>}
                    {this.state.controlPlainPanelClass === 'height-0' &&
                    <div className="showHideBar" onClick={this.show}><i className="arrow down"></i></div>}
                </div>
            </div>
        )
    }
}
