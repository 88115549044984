import React from 'react';
import './Login.css'

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            successful: true
        }
    }

    componentDidMount() {
        let validTill = localStorage.getItem('tokenValidTill') || -1;
        if (validTill >= new Date().getTime()) {
            this.state.password = localStorage.getItem('token'); // ???
            this.setState({password: this.state.password});
            this.handleSubmit();
        }
        localStorage.clear();
    }

    handleSubmit(event) {
        event && event.preventDefault();

        this.setState({
            successful: true
        });

        fetch(window._env_.DATA_STREAM_EMULATOR_URL + '/verify',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({token: this.state.password})
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('token', data.token);
                localStorage.setItem('tokenValidTill', "" + (20 * 60 * 1000 + new Date().getTime()));
                this.props.setToken(data.token);
            })
            .catch((e) => {
                this.setState({
                    successful: false
                })
            });
    }

    setPassword(event) {
        this.setState({
            password: event.target.value
        })
    }

    render() {
        return (
            <div className="center-block">
                <div className="center-block-2">
                    <h1>Login</h1>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div>
                            <label htmlFor="token" className="margin-right-20">Password</label>
                            <input id="token" name="token" type="password" value={this.state.password}
                                   onChange={(e) => this.setPassword(e)}/>
                        </div>
                        <div className="margin-top-10">
                            <button type="submit" className="margin-right-10">Login</button>
                            <span className={"red " + (this.state.successful ? "display-none" : "")}>Wrong password provided</span>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

}
