export function schedule(callback, interval, status) {
    if (!status) {
        status = {status: 'running', type: 'timeout'}
    } else if (status.status !== 'running') {
        return status;
    }
    executor(callback).then(() => {
        if (status.status === 'running') {
            status.processId = setTimeout(() => schedule(callback, interval, status), interval)
        }
    });

    return status;
}

export function scheduleNonBlocking(callback, interval, status) {
    if (!status) {
        status = {status: 'running', type: 'interval'}
    } else if (status.status !== 'running') {
        return status;
    }

    status.processId = setInterval(callback, interval)
    return status;
}

export function unschedule(status) {
    if (status) {
        status.status = 'stopped';
        status === 'timeout' ? clearTimeout(status.processId) : clearInterval(status.processId);
    }
}

export function isScheduled(status) {
    if (!status) {
        return false;
    }
    return status.status === 'running';
}

async function executor(callback) {
    await callback();
}
