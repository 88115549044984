import React from 'react';
import eventBus from "./EventBus";
import './DataPlayer.css'
import Canvas from "./Canvas";
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';

export default class DataPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            track: {},
            horses: [{show: true}, {show: true}, {show: true}, {show: true}, {show: true}, {show: true}, {show: true}, {show: true}, {show: true}]
        }
        this.points = [];
        this.lastPointTime = null;
        this.colors = ["#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080", "#0000FF"];

        this.handleMessage = this.handleMessage.bind(this);
    }

    componentDidMount(props) {
        let sockJS = new SockJS(window._env_.STOMP_URL);
        let stompClient = Stomp.over(sockJS);
        stompClient.debug = f => f;
        stompClient.connect({},
            () => {
                stompClient.subscribe("/topic/race", this.handleMessage);

                const currentDate = new Date();
                fetch(window._env_.HISTORY_URL + "?" + new URLSearchParams({
                    from: new Date(currentDate.getTime() - 10 * 60_000).toISOString(),
                    to: currentDate.toISOString(),
                }))
                    .then(response => response.json())
                    .then(points => {
                        if (points.length) {
                            for (const point of points) {
                                this.points[Math.trunc(Date.parse(point.timestamp) / 100)] = point;
                            }
                            this.setState({
                                time: points[points.length - 1].timestamp
                            });
                        }
                    });
            },
            (error) => {
                console.log('error ' + error)
            });


        this.fetchTrackData();

        eventBus.on("serve", (data) => {
            if (data.type === 'data') {
                this.clearState();
                this.fetchTrackData();
            }
        });

        eventBus.on("terminate", (data) => {
            if (data.type === 'data') {
                this.clearState();
            }
        });

        eventBus.on("playStatus", (playData) => {
            this.setState({
                videoTimestamp: playData.time
            });

            const key = Math.trunc(playData.time / 100);
            const point = this.points[key];
            if (point) {
                this.setState({
                    objects: point.objects
                });
            } else {
                this.state.objects && this.setState({
                    objects: []
                });
            }
        })
    }

    fetchTrackData() {
        fetch(window._env_.DATA_STREAM_EMULATOR_URL + '/track-data')
            .then(response => response.json())
            .then(data => {
                this.setState({
                    track: data
                });
            });
    }

    clearState() {
        this.setState({objects: []});
    }

    handleMessage(stompMessage) {
        const data = JSON.parse(stompMessage.body);
        this.points[Math.trunc(Date.parse(data.timestamp) / 100)] = data;
        this.setState({
            time: data.timestamp,
            timestamp: Date.parse(data.timestamp)
        });
    }

    getStreamTimeDifference() {
        if (this.state.videoTimestamp && this.state.timestamp) {
            return this.state.videoTimestamp - this.state.timestamp;
        }
    }

    handleHorseShowHide(event, index, value) {
        value.show = event.target.checked;
        this.setState({horses: this.state.horses});
    }

    render() {
        return (
            <div className="data-player">
                <div className="flex-column">
                    <h2>Data</h2>
                    <div>Last point time: {this.state.time || 'N/A'} | Data / video
                        difference: {this.getStreamTimeDifference() || NaN} msec
                    </div>
                    <div className="canvas-container">
                        <Canvas className="canvas" width="820" height="435" objects={this.state.objects}
                                track={this.state.track} horses={this.state.horses} colors={this.colors}>
                            Your browser does not support canvas.</Canvas>
                    </div>
                </div>
                <div className="legend">
                    {this.state.horses.map((value, index) => {
                        return <span key={"horseBlock" + index} className="nowrap horse-selector">
                            <span className="dot" style={{'backgroundColor': this.colors[index]}}></span>

                            <input id={"horse" + index} name={"horse" + index}
                                   className="css-checkbox lrg"
                                   type="checkbox"
                                   value={value.show}
                                   checked={value.show}
                                   onChange={(e) => this.handleHorseShowHide(e, index, value)}
                                   size="1"/>

                            <label htmlFor={"horse" + index} className="css-label lrg vlad">Horse {index + 1}</label>
                        </span>
                    })}
                </div>
            </div>
        );
    }

}
